import React, { useContext } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/styles'

// Components
import { FormSection } from '../'

// State
import { appStateManager } from '../../state/app-state'
import AppContext from '../../Contexts/AppContext'

const useStyle = makeStyles({
    form: {
        margin: 'auto',
        width: '95%',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        color: '#2B2B2B',
    },
    row: {
        display: 'flex',
        marginBottom: '1rem',
    },
    col: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    formInput: {
        marginBottom: '10px',
    },
    heading: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        margin: '0.5rem 0 0.25rem 0',
    },
    subHeading: {
        fontSize: '1rem',
        fontWeight: 'bold',
        marginBottom: '0.5rem',
    },
    checkbox: {
        paddingLeft: 0,
    },
})

const JuniorExtras = () => {
    const { state, dispatch } = useContext<IAppContext>(AppContext)
    const classes = useStyle()

    const onPreArrivalFluencyBuilderChanged = ({
        target,
    }: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
            type: appStateManager.ACTION_OPTIONAL_EXTRA_CHANGED,
            payload: {
                field: 'preArrivalFluencyBuilder',
                value: target.checked,
            },
        })

    const onPostCourseFluencyPlusChanged = ({
        target,
    }: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
            type: appStateManager.ACTION_OPTIONAL_EXTRA_CHANGED,
            payload: { field: 'postCourseFluencyPlus', value: target.checked },
        })

    const onOneToOneChanged = ({
        target,
    }: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
            type: appStateManager.ACTION_OPTIONAL_EXTRA_CHANGED,
            payload: { field: 'oneToOne', value: target.checked },
        })

    const onHoodieOnArrivalChanged = ({
        target,
    }: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
            type: appStateManager.ACTION_OPTIONAL_EXTRA_CHANGED,
            payload: { field: 'hoodieOnArrival', value: target.checked },
        })

    return (
        <FormSection title="Extras">
            <div className={classes.form}>
                <div className={classes.row}>
                    {/* <div className={classes.col}>
                        <h2 className={classes.heading}>
                            Pre-Arrival Fluency Builder
                        </h2>
                        <span className={classes.subHeading}>
                            Online English Course All Levels Available
                        </span>
                        <span>(8 Weeks, 1 evening per week)</span>
                        <span>Apr 5 to May 24</span>
                        <span>Wednesday 6pm - 7.15pm CET+1</span>
                        <span>Max. 12 Students per Group</span>
                        <span>€ 118</span>
                        <div>
                            <Checkbox
                                id="opt1"
                                className={classes.checkbox}
                                checked={
                                    state.extras.preArrivalFluencyBuilder
                                        .selected
                                }
                                onChange={onPreArrivalFluencyBuilderChanged}
                            />
                            <span>Include</span>
                        </div>
                    </div> */}
                    <div className={classes.col}>
                        <h2 className={classes.heading}>
                            ICE Hoodie on Arrival
                        </h2>
                        <span>€ 30</span>
                        <div>
                            <Checkbox
                                id="opt4"
                                className={classes.checkbox}
                                checked={state.extras.hoodieOnArrival.selected}
                                onChange={onHoodieOnArrivalChanged}
                            />
                            <span>Include</span>
                        </div>
                    </div>
                </div>
            </div>
        </FormSection>
    )
}

export default JuniorExtras
